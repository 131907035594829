import React from "react";
import { Box, Button, Stack } from "@mui/material";
import SponsorsBar from "../components/SponsorsBar";
import ImageCarousel from "../general-components/ImageCarousel";
import SatrangDescription from "../components/satrang/SatrangDescription";
import Satrang24 from "../components/satrang/satrang24/Satrang24";
import Satrang23 from "../components/satrang/satrang23/Satrang23";
import { scrollToTop } from "../utils/utils";
import { useNavigate } from "react-router-dom";
import { satrang24TicketLinks } from "../seeds/satrang.page";

const Satrang = (props) => {
  const { sponsorsData, satrangImageCarouselImages } = props;
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/sponsor-us`);
    scrollToTop();
  };
  return (
    <>
      <Box sx={{ px: [2, 20], py: [2, 5] }}>
        <Stack spacing={2}>
          <ImageCarousel images={satrangImageCarouselImages} />
          <SatrangDescription />
          <Stack direction="row" spacing={2}>
            <Button variant="contained" onClick={handleClick} fullWidth>
              Become our sponsor
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                window.open(satrang24TicketLinks);
              }}
              fullWidth
            >
              Buy tickets for Satrang 2024
            </Button>
          </Stack>
          <Satrang24 />
          <Satrang23 />
        </Stack>
      </Box>
      <Box sx={{ mt: 5 }}>
        <SponsorsBar sponsorsData={sponsorsData} />
      </Box>
    </>
  );
};

export default Satrang;
