import { Grid, Typography, Box } from "@mui/material";
import React from "react";

import { title, satrangDescription } from "../../seeds/satrang.page.js";
import video from "../../public/videos/satrang_video.mp4";

const SatrangDescription = () => {
  return (
    <Box>
      <Typography
        variant="h3"
        sx={{
          textAlign: "center",
          mb: 2,
        }}
      >
        {title}
      </Typography>
      <Grid container>
        <Grid item xs={12} sm={6} sx={{ p: 1 }}>
          <video
            src={video}
            style={{
              width: "100%",
              height: "-webkit-fill-available",
            }}
            controls
          ></video>
        </Grid>
        <Grid item xs={12} sm={6} sx={{ p: 1 }}>
          <Typography
            dangerouslySetInnerHTML={{ __html: satrangDescription }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default SatrangDescription;
