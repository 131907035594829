import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { Box } from "@mui/material";

import Home from "./pages/home";
import About from "./pages/about";
import Event from "./pages/event/event";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

import Satrang from "./pages/satrang";
import { getSessionStorage, shouldCallGoogleSheetsApi } from "./utils/utils";
import BecomeOurSponsor from "./pages/become-our-sponsor";
import ConditionallyFetchFromSheets from "./components/data-fecther/conditionally-fetch-from-sheets";
import { useEffect, useState } from "react";
import { initAmplitude } from "./utils/amplitude";

function App() {
  const shouldFetchData = shouldCallGoogleSheetsApi();
  const [fetchData, setFetchData] = useState(shouldFetchData);

  let eventsData = getSessionStorage("events");
  const aboutUsData = getSessionStorage("aboutUs");
  const categoriesData = getSessionStorage("categories");
  const sponsorsData = getSessionStorage("sponsors");
  const footerData = getSessionStorage("footer");
  const satrangImageCarouselData = getSessionStorage("satrangImageCarousel");

  const filteredEventsData = eventsData?.filter(
    (event) => event.active === "TRUE" || event.active === "true"
  );

  eventsData = filteredEventsData;

  useEffect(() => {
    initAmplitude();
  }, []);

  if (fetchData) {
    return (
      <ConditionallyFetchFromSheets
        callbackToRunAfterFetchingData={() => {
          setFetchData(false);
        }}
      />
    );
  }

  return (
    <Router>
      <Navbar />
      <Routes>
        <Route
          exact
          path="/"
          element={
            <Box sx={{ mt: "68px" }}>
              <Home
                eventsData={eventsData}
                categoriesData={categoriesData}
                sponsorsData={sponsorsData}
              />
            </Box>
          }
        />
        <Route
          path="/about"
          element={
            <Box sx={{ my: "68px" }}>
              <About aboutUsData={aboutUsData} />
            </Box>
          }
        />
        <Route
          path="/events"
          element={
            <Box sx={{ my: "68px" }}>
              <Event categoriesData={categoriesData} eventsData={eventsData} />
            </Box>
          }
        />
        <Route
          path="/satrang"
          element={
            <Box sx={{ mt: "68px" }}>
              <Satrang
                sponsorsData={sponsorsData}
                satrangImageCarouselImages={satrangImageCarouselData}
              />
            </Box>
          }
        />
        <Route
          path="/sponsor-us"
          element={
            <Box sx={{ mt: "68px" }}>
              <BecomeOurSponsor />
            </Box>
          }
        />
      </Routes>
      <Box sx={{ bgcolor: "#303841" }}>
        <Footer footerData={footerData} />
      </Box>
    </Router>
  );
}

export default App;
